import React from 'react'
import { graphql } from 'gatsby'

import * as styles from '../styles/pages/all-brands.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const Head = () => <title>All Brands | GROWoneAFRICA&trade;</title>

const AllBrandsPage = ({ data }) => {
  const allBrands = data.allSanityBrand.nodes

  const brandElements = allBrands.map((brand) => {
    const {
      name,
      logo,
      biography
    } = brand

    return (
      <article className={styles.brandCard}>
        <div className={styles.imageContainer}>
          <GatsbyImage image={getImage(logo.asset)} alt={name} />
        </div>
        <h2>{name}</h2>
        <p>{biography}</p>
      </article>
    )
  })

  return (
    <main className={`page-container ${styles.allBrandsPage}`}>
      <h1>Brands</h1>
      <section className={styles.brandsContainer} >
        {brandElements}
      </section>
    </main>
  )
}

export const query = graphql`
  query AllBrandsPageQuery {
    allSanityBrand(sort: {fields: name}) {
      nodes {
        name
        logo {
          asset {
            gatsbyImageData( height: 200 )
          }
        }
        biography
      }
    }
  }
`

export default AllBrandsPage